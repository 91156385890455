<template>
    <div class="layout-breadcrumb-container p-d-flex p-jc-between p-ai-center p-shadow-1">
        <Breadcrumb :home="home" :model="items" class="layout-breadcrumb p-pl-4 p-py-2"> </Breadcrumb>
    </div>
</template>

<script>
export default {
    data() {
        return {
            home: { icon: 'pi pi-home', to: '/' },
            items: [],
        };
    },
    watch: {
        $route() {
            this.watchRouter();
        },
    },
    created() {
        this.watchRouter();
    },
    methods: {
        watchRouter() {
            if (this.$router.currentRoute.value.meta.breadcrumb) {
                this.items = [];
                const bc = this.$router.currentRoute.value.meta.breadcrumb[0];
                const currentPath = this.$router.currentRoute.value.path;
                if (
                    this.$router.currentRoute.value.meta.breadcrumb[0]?.label === 'Partner Selector' ||
                    this.$router.currentRoute.value.meta.breadcrumb[0]?.parent === 'Partner Selector'
                ) {
                    for (let pro in bc) {
                        this.items.push({ label: bc[pro], to: '/member/partnerselector' });
                    }
                } else {
                    for (let pro in bc) {
                        this.items.push({ label: bc[pro], to: currentPath });
                    }
                }
            }
        },
    },
};
</script>

<style scoped></style>
