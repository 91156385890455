<template>
    <p class="field-title">{{ label }}
        <TooltipComponent v-if="item.description" :description="item.description" />
    </p>

    <InputText :disabled="isChannel" class="input-text" type="number" :value="modelValue" :placeholder="placeholder"
        @input="handleInput" />

    <p v-if="v$.modelValue.$error" class="error-msg">
        {{ error_message }}
    </p>
</template>

<script>
import { computed, watch } from 'vue';
import InputText from 'primevue/inputtext';
import { useVuelidate } from '@vuelidate/core';
import { between, minLength, maxLength } from '@vuelidate/validators';
import TooltipComponent from './TooltipComponent.vue';
import { getIsChannel } from '../../service/common';

export default {
    name: 'NumberInputComponent',
    components: {
        InputText,
        TooltipComponent,
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        modelValue: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        error_message: {
            type: String,
        },
        validation_rules: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            isChannel: false,
        };
    },
    created() {
        this.isChannel = getIsChannel()
    },
    setup(props, { emit }) {
        const rules = computed(() => {
            const validationRules = {};
            if (props.validation_rules.betweenValue) {
                validationRules.between = between(
                    props.validation_rules.betweenValue.min,
                    props.validation_rules.betweenValue.max
                );
            } 
            // else {
            //     validationRules.minLength = minLength(props.validation_rules.minLength);
            //     validationRules.maxLength = maxLength(props.validation_rules.maxLength);
            // }
            return {
                modelValue: validationRules,
            };
        });

        const v$ = useVuelidate(rules, props);

        watch(
            () => props.modelValue,
            () => {
                v$.value.$touch();
                if (!v$.value.$error) {
                    emit('isValid', true);
                } else {
                    emit('isValid', false);
                }
            }
        );

        const handleInput = (event) => {
            emit('update:modelValue', event.target.value);

            v$.value.$touch();
            if (!v$.value.$error) {
                emit('isValid', true);
            } else {
                emit('isValid', false);
            }
        };

        return { v$, handleInput };
    },
};
</script>
