import { getApi } from './common/apis';

export default class RfpInsightsService {
    async getRfpListInsights(data) {
        try {
            let url = `v1/rfp/list?start_date=${data.startDate}&end_date=${data.endDate}`;

            if (data.channelId) {
                url += `&channel_id=${data.channelId}`;
            }

            if (data.partnerId) {
                url += `&partner_id=${data.partnerId}`;
            }

            var response = await getApi(url, process.env.VUE_APP_COMMON_BASE_URL);
            return await response;
        } catch (err) {
            console.error('Error', err.stack);
            return err.response;
        }
    }
}
