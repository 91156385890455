import { getApi, postApi } from './common/apis';

export default class UserMangementService {
    async getAccounts(subnetworkId) {
        var accounts;
        if (subnetworkId) {
            console.log('Calling API with subnetworkId', subnetworkId);
            accounts = await getApi(`v1/account?subnetwork_id=${subnetworkId}`);
        } else {
            console.log('Calling API without subnetworkId');
            accounts = await getApi(`v1/account`);
        }
        return accounts;
    }

    async getRoles() {
        var roles = await getApi(`v1/user-roles`);
        return roles;
    }

    async addNewUser(payload) {
        var userResponse = await postApi(`v1/user`, payload);
        return userResponse;
    }
}
