<template>
    <div class="layout-topbar p-shadow-4">
        <div class="layout-topbar-left">
            <router-link class="layout-topbar-logo" :to="routePath">
                <img id="logo" src="assets/layout/images/arenacx_white_logo.png" alt="ultima-layout"
                    style="height: 2.25rem" />
            </router-link>

            <a v-show="sidebarIcon" class="layout-menu-button p-shadow-6 p-ripple" @click="onMenuButtonClick($event)"
                v-ripple>
                <i class="pi pi-chevron-right"></i>
            </a>

            <a class="layout-topbar-mobile-button p-ripple" @click="onTopbarMobileButtonClick($event)" v-ripple>
                <i class="pi pi-ellipsis-v fs-large"></i>
            </a>
        </div>

        <div class="layout-topbar-right" :class="{ 'layout-topbar-mobile-active': mobileTopbarActive }">
            <div class="layout-topbar-actions-left">
                <MegaMenu v-if="userIsArenaCX || userIsChannel" :model="arenacxItems"
                    class="layout-megamenu custom-megamenu-styles">
                    <!-- <template #item="{ item }">
                        <a
                            class="flex items-center cursor-pointer px-4 py-2 overflow-hidden relative font-semibold text-lg uppercase"
                            style="border-radius: 2rem"
                        >
                            <span>{{ item.label }}</span>
                        </a>
                    </template> -->
                </MegaMenu>
                <div v-if="!userIsArenaCX && !userIsChannel">{{ accountName }}</div>
            </div>

            <div class="layout-topbar-actions-right">
                <ul class="layout-topbar-items">
                    <li class="layout-topbar-item layout-search-item" v-if="false">
                        <!-- Hidden now, but may turn on later -->
                        <a class="layout-topbar-action rounded-circle p-ripple"
                            @click="onTopbarItemClick($event, 'search')" v-ripple>
                            <i class="pi pi-search fs-large"></i>
                        </a>

                        <transition name="layout-search-panel" @enter="onEnter">
                            <div class="layout-search-panel p-inputgroup" v-show="searchActive"
                                @click="onSearchContainerClick">
                                <span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>
                                <InputText ref="searchInput" type="text" placeholder="Search"
                                    @keydown="onSearchKeydown($event)" />
                                <span class="p-inputgroup-addon">
                                    <Button icon="pi pi-times" class="p-button-rounded p-button-text p-button-plain"
                                        @click="changeSearchActive"></Button>
                                </span>
                            </div>
                        </transition>
                    </li>

                    <li class="layout-topbar-item">
                        <!-- <a
                            v-if="userIsArenaCX"
                            class="layout-topbar-action p-d-flex p-dir-row p-jc-center p-ai-center p-px-2 p-ripple"
                            style=""
                            @click="onTopbarItemClick($event, 'collaboration')"
                            v-ripple
                        >
                            <img
                                src="assets/layout/images/puzzle_icon.png"
                                alt="avatar"
                                style="height: 32px; margin-right: 8px"
                            />
                            Collaboration
                        </a> -->
                        <!-- 

// Hide Service & Support
                        <a
                            class="layout-topbar-action p-d-flex p-dir-row p-jc-center p-ai-center p-px-2 p-ripple"
                            style=""
                            @click="onTopbarItemClick($event, 'serviceAndSupport')"
                            v-ripple
                        >
                            <img
                                src="assets/layout/images/icon_headset.png"
                                alt="avatar"
                                style="width: 32px; height: 32px; margin-right: 8px"
                            />
                            Service &amp; Support
                        </a> -->

                        <a class="layout-topbar-action p-d-flex p-dir-row p-jc-center p-ai-center p-px-2 rounded-circle p-ripple initial-circle"
                            @click="onTopbarItemClick($event, 'profile')" v-ripple>
                            {{ userInitials }}
                        </a>

                        <transition name="layout-topbar-action-panel">
                            <ul class="layout-topbar-action-panel p-shadow-6" v-show="activeTopbarItem === 'profile'">
                                <!-- <li class="layout-topbar-action-item">
                                    <a class="p-d-flex p-flex-row p-ai-center">
                                        <i class="pi pi-user-edit pi-fw p-mr-2"></i>
                                        <router-link to="/userprofile">User Profile</router-link>
                                    </a>
                                </li> -->
                                <!-- <li class="layout-topbar-action-item">
                                    <a class="p-d-flex p-flex-row p-ai-center">
                                        <i class="pi pi-file-o" :class="{ 'p-mr-2': !isRTL, 'p-ml-2': isRTL }"></i>
                                        <router-link to="/termsofusage">Terms of Usage</router-link>
                                        Terms of Usage
                                    </a>
                                </li> -->
                                <li class="layout-topbar-action-item">
                                    <a class="p-d-flex p-flex-row p-ai-center" @click="openModal">
                                        <i class="pi pi-file-o" :class="{ 'p-mr-2': !isRTL, 'p-ml-2': isRTL }"></i>
                                        Terms of Usage
                                    </a>
                                </li>
                                <li class="layout-topbar-action-item">
                                    <a class="p-d-flex p-flex-row p-ai-center" @click="signOut">
                                        <i class="pi pi-power-off orange-color"
                                            :class="{ 'p-mr-2': !isRTL, 'p-ml-2': isRTL }"></i>
                                        <span>Logout</span>
                                    </a>
                                </li>
                            </ul>
                        </transition>
                        <transition name="layout-topbar-action-panel">
                            <ul class="layout-topbar-action-panel p-shadow-6"
                                v-show="activeTopbarItem === 'serviceAndSupport'">
                                <li class="layout-topbar-action-item">
                                    <a class="p-d-flex p-flex-row p-ai-center">
                                        <i class="pi pi-compass pi-fw"></i>
                                        <span style="margin-left: 8px">
                                            <a href="https://help.arenacx.com/hc/en-us/requests/new"
                                                target="_zendesk">Submit a
                                                Request</a>
                                        </span>
                                    </a>
                                </li>
                                <li class="layout-topbar-action-item">
                                    <a class="p-d-flex p-flex-row p-ai-center">
                                        <i class="pi pi-cog pi-fw"></i>
                                        <span style="margin-left: 8px">
                                            <router-link to="/incidentalert">Incident Alert</router-link>
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </transition>

                        <transition name="layout-topbar-action-panel">
                            <ul class="layout-topbar-action-panel p-shadow-6"
                                v-show="activeTopbarItem === 'collaboration'">
                                <li class="layout-topbar-action-item" v-if="true">
                                    <a class="p-d-flex p-flex-row p-ai-center">
                                        <i class="pi pi-users pi-fw orange-color"></i>
                                        <span style="margin-left: 8px">
                                            <router-link to="/contacts">My Contacts</router-link>
                                        </span>
                                    </a>
                                </li>
                                <li class="layout-topbar-action-item" v-if="true">
                                    <a class="p-d-flex p-flex-row p-ai-center">
                                        <i class="pi pi-video pi-fw blue-color"></i>
                                        <span style="margin-left: 8px">
                                            <router-link to="/videomeeting">Video Conference</router-link>
                                        </span>
                                    </a>
                                </li>
                                <li class="layout-topbar-action-item" v-if="true">
                                    <a class="p-d-flex p-flex-row p-ai-center">
                                        <i class="pi pi-users pi-fw blue-brown"></i>
                                        <span style="margin-left: 8px">
                                            <router-link to="/messagecenter">Message Center</router-link>
                                        </span>
                                    </a>
                                </li>
                                <li class="layout-topbar-action-item" v-if="true">
                                    <a class="p-d-flex p-flex-row p-ai-center">
                                        <i class="pi pi-calendar pi-fw"></i>
                                        <span style="margin-left: 8px">
                                            <router-link to="/schedulemeeting">Schedule Meeting</router-link>
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </transition>
                    </li>

                    <!-- 
					<li class="layout-topbar-item">
						<a class="layout-topbar-action rounded-circle p-ripple" @click="onRightPanelButtonClick($event)" v-ripple>
							<i class="pi fs-large" :class="{'pi-arrow-left': !isRTL, 'pi-arrow-right': isRTL}"></i>
						</a>
					</li>
					-->
                </ul>
            </div>
        </div>

        <div v-if="showModal" class="modal-backdrop">
            <div class="modal-content">
                <button class="close-button" @click="closeModal">X</button>
                <iframe src="https://arenacx.com/terms-conditions/" class="iframe-content" frameborder="0"
                    allowfullscreen>
                </iframe>
            </div>
        </div>
    </div>
</template>

<script>
import { Auth } from 'aws-amplify';
// import router from './router';
import MarketplaceApiService from './service/MarketplaceApiService';
import CurrentUserService from './service/CurrentUserService';

export default {
    emits: [
        'menubutton-click',
        'topbar-menubutton-click',
        'topbaritem-click',
        'rightpanel-button-click',
        'topbar-mobileactive',
        'search-click',
        'search-toggle',
    ],
    data() {
        return {
            routePath: '/',
            accountName: '',
            userInitials: '',
            userIsArenaCX: false,
            userIsChannel: false,
            searchText: '',
            items: [],
            isLogin: true,
            sidebarIcon: true,
            showModal: false,
            arenacxItems: [],
        };
    },
    props: {
        horizontal: {
            type: Boolean,
            default: false,
        },
        topbarMenuActive: {
            type: Boolean,
            default: false,
        },
        activeTopbarItem: String,
        mobileTopbarActive: Boolean,
        searchActive: Boolean,
    },

    apiService: null,
    currentUser: null,

    created() {
        this.getUserInfo();
        this.apiService = new MarketplaceApiService();
        this.userInitials = '';
        this.currentUser = new CurrentUserService();
        this.userIsArenaCX = this.currentUser.userIsArenaCX;
        this.initializeMenu();  // Add this line
    },
    watch: {
        $route() {
            window.location.href.includes('/member/partnerselector/filter') ||
                window.location.href.includes('/member/partnerselector/comparison')
                ? (this.sidebarIcon = false)
                : (this.sidebarIcon = true);
        },
    },

    methods: {
        async getUserInfo() {
            var cognitoUser = await Auth.currentAuthenticatedUser();
            this.apiService.getUserDetails(cognitoUser.username).then((data) => {
                var userDetail = data;
                this.userInitials = userDetail.firstName.substring(0, 1) + userDetail.lastName.substring(0, 1);
                this.accountName = userDetail.account.name;
                if (userDetail.userRole.roleKey.startsWith('acx')) {
                    this.userIsArenaCX = true;
                    this.initializeMenu();
                    this.routePath = '/';
                } else if (userDetail.userRole.roleKey.startsWith('partner')) {
                    this.routePath = `/partner/home`;
                    this.userIsArenaCX = false;
                } else if (userDetail.userRole.roleKey.startsWith('member')) {
                    this.routePath = `/member/home`;
                    this.userIsArenaCX = false;
                } else if (userDetail.userRole.roleKey.startsWith('channel')) {
                    this.routePath = `/channel/home`;
                    this.userIsChannel = true;
                    this.initializeMenu();
                }

                this.userInfoGA(userDetail);
            });
        },

        async signOut() {
            let isSignOutSuccess = false;
            try {
                await Auth.signOut({ global: true });
                isSignOutSuccess = true;
            } catch (error) {
                console.error('error signing out: ', error);
            }
            if (isSignOutSuccess == false) {
                try {
                    await Auth.signOut();
                    isSignOutSuccess = true;
                } catch (error) {
                    console.error('error signing out: ', error);
                }
            }

            localStorage.removeItem('templates');

            Object.keys(localStorage).forEach((key) => {
                key?.toLowerCase()?.startsWith('cognito') ||
                    key?.toLowerCase()?.startsWith('amplify') ||
                    key === 'pro_refreshToken' ||
                    key === 'pro_marketplaceUser' ||
                    key === 'pro_jwt';
            });

            this.$router.push('/login');
        },
        initializeMenu() {
            const businessDevItems = [
                { label: 'Partner Matching', icon: 'pi pi-fw pi-th-large', to: '/matching' },
                { label: 'Question Library', icon: 'pi pi-fw pi-th-large', to: '/question-library' },
            ];

            // Only add ORA Management if not a channel user
            if (!this.userIsChannel) {
                businessDevItems.splice(1, 0, { label: 'ORA Management', icon: 'pi pi-fw pi-th-large', to: '/oras' });
            }

            this.arenacxItems = [
                {
                    label: 'Special Utilities',
                    items: [
                        [
                            {
                                label: 'Business Development',
                                items: businessDevItems
                            },
                            {
                                label: 'User Management',
                                items: [
                                    { label: 'Add User', icon: 'pi pi-fw pi-mobile', to: '/user-management' },
                                    { label: 'Add Account', icon: 'pi pi-fw pi-mobile', to: '/account-management' },
                                ],
                            },
                        ],
                    ],
                },
            ];
        },


        userInfoGA(userDetail) {
            //Google Analytics User Info Capture
            const ACCOUNT_TYPE_ARENACX = 0;
            const ACCOUNT_TYPE_MEMBER = 1;
            const ACCOUNT_TYPE_PARTNER = 2;
            const userInfoData = {};
            switch (userDetail.account.accountTypeId) {
                case ACCOUNT_TYPE_PARTNER:
                    userInfoData.user_role = 'Partner';
                    break;
                case ACCOUNT_TYPE_MEMBER:
                    userInfoData.user_role = 'Member';
                    break;
                case ACCOUNT_TYPE_ARENACX:
                    userInfoData.user_role = 'Admin';
                    break;
                default:
                    userInfoData.user_role = 'Unknown';
                    break;
            }
            let timeNow = new Date().getTime();
            userInfoData.company_name = userDetail.account.name;
            userInfoData.timestamp = timeNow;
            this.$gtag.event('user_info', userInfoData);
            window.localStorage.setItem('companyName', userInfoData.company_name);
        },

        onSearchContainerClick(event) {
            this.$emit('search-click', event);
        },
        changeSearchActive(event) {
            this.$emit('search-toggle', event);
        },
        onMenuButtonClick(event) {
            this.$emit('menubutton-click', event);
        },
        onTopbarMenuButtonClick(event) {
            this.$emit('topbar-menubutton-click', event);
        },
        onTopbarItemClick(event, item) {
            if (item === 'search') {
                this.$emit('search-toggle', event);
            }

            this.$emit('topbaritem-click', { originalEvent: event, item: item });
        },
        onTopbarMobileButtonClick(event) {
            this.$emit('topbar-mobileactive', event);
        },
        onRightPanelButtonClick(event) {
            this.$emit('rightpanel-button-click', event);
        },
        onSearchKeydown(event) {
            if (event.keyCode == 13) {
                this.$emit('search-toggle', event);
            }
        },
        onEnter() {
            if (this.$refs.searchInput) {
                this.$refs.searchInput.$el.focus();
            }
        },
        openModal() {
            this.showModal = true;
        },
        closeModal() {
            this.showModal = false;
        },
    },
    computed: {
        topbarItemsClass() {
            return [
                'topbar-items animated fadeInDown',
                {
                    'topbar-items-visible': this.topbarMenuActive,
                },
            ];
        },
        isRTL() {
            return this.$appState.RTL;
        },
    },
};
</script>

<style scoped>
.initial-circle {
    background-color: #5ba595;
    font-size: 125%;
    font-weight: bold;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* dim background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.modal-content {
    background-color: white;
    padding: 20px;
    position: relative;
    width: 80%;
    height: 80%;
    display: flex;
    flex-direction: column;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: red;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
}

.custom-megamenu-styles .p-megamenu .p-menuitem-content {
    background-color: black !important;
}

.iframe-content {
    width: 100%;
    height: 100%;
}
</style>
