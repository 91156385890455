<template>
    <div>
        <div class="p-d-flex p-jc-between">
            <p class="profile-title">Answer Bank</p>
            <div v-show="rootDirtyInd">
                <Button label="Cancel" class="p-button p-component p-button-outlined button" @click="cancelAnswerBank" />
                <Button label="Save" class="p-button p-component button" @click="saveAnswerBank" />
                <FloatingSaveCancel @save="saveAnswerBank" @cancel="cancelAnswerBank" />
            </div>
        </div>

        <div class="card flex justify-center">
            <TreeSelect
                v-model="activeDirectory"
                :options="directoryTreeData"
                placeholder="Select the Answer Bank to review"
                style="width: 50rem"
                inputStyle="{fontSize: '1.2rem'}"
                fluid="true"
                selectionMode="single"
                @change="directoryChanged"
            />
            <span class="directoryTitle">{{ activeDirectoryTitle }}</span>

            <div v-if="showInputSwitch" class="radio-button-container flex align-items-center">
                <div class="flex align-items-center mr-2">
                    <div v-if="isAdmin" class="field-radiobutton mr-4">
                        <RadioButton
                            v-model="checked"
                            inputId="status_disabled"
                            name="status"
                            :value="false"
                            @change="onCheckedClick"
                        />
                        <label for="status_disabled">Hidden from customers</label>
                    </div>
                    <div v-if="isAdmin" class="field-radiobutton">
                        <RadioButton
                            v-model="checked"
                            inputId="status_enabled"
                            name="status"
                            :value="true"
                            @change="onCheckedClick"
                        />
                        <label for="status_enabled">Visible to customers</label>
                    </div>
                </div>
                <TooltipComponent  v-if="isAdmin"
                    description="Set this to 'Hidden' if you do not want your product or service seen by customers, as when you are answering the questions below for the first time, or if you need to take it off the market entirely. 
                    
                    Set this to 'Visible' when you want customers to see this product/service in their search results."
                />
            </div>
        </div>

        <Accordion :activeIndex="0" @click="onTabClick">
            <AccordionTab v-for="(item, index) in templates" :key="index" :header="item.category_name">
                <Wrapper
                    :parentItem="item"
                    v-model="inputs[item.category_name]"
                    :answers="answers"
                    @update:modelValue="updateAnswers"
                    @isValid="handleValidation"
                />
            </AccordionTab>
        </Accordion>
    </div>
</template>

<script>
import FloatingSaveCancel from '../components/common/FloatingSaveCancel.vue';
import CurrentUserService from '../service/CurrentUserService';
import AnswerBankService from '../service/AnswerBankService';
import { getAccount } from '../service/common';
import Wrapper from '../components/answerBank/Wrapper';
import _ from 'lodash';
import TooltipComponent from './../components/Tooltip.vue';

window.setTimeout(function () {
    window.onpopstate = () => {
        this.sectionTrackGA();
    };
}, 1);
window.setTimeout(function () {
    window.onbeforeunload = () => {
        this.sectionTrackGA();
    };
}, 1);
export default {
    components: {
        FloatingSaveCancel,
        Wrapper,
        TooltipComponent,
    },
    name: 'AnswerBank',
    data() {
        return {
            activeTab: [0],
            saveInd: false,
            answerBankData: [],
            partnerId: null,
            isAdmin: false,
            lastClicked: new Date().getTime(),
            accordionLabel: null,
            templates: [],
            inputs: {},
            answers: [],
            newAnswerBankData: [],
            rootDirtyInd: false,
            fieldErrors: {},
            hasErrors: false,
            checked: false,
            directoryTreeData: null,
            activeDirectory: null,
            activeDirectoryTitle: null,
            qsid: null,
        };
    },
    created() {
        this.userService = new CurrentUserService();
        this.answerBankService = new AnswerBankService();
        this.userIsACX = this.userService.marketplaceUser.userRole.roleKey.startsWith('acx:');
        this.isAdmin = getAccount();
        this.directoryTreeData = this.answerBankService.getDirectoryTreeData();
    },
    async mounted() {
        // this.getTemplateForQuestionSet();
        // await this.getAnswers();
        // this.initializeInputs();
        localStorage.removeItem('qsid');
        this.updateQsid();
        this.getAccessData();
    },
    computed: {
        showInputSwitch() {
            return this.qsid && parseInt(this.qsid) > 1;
        },
    },
    watch: {
        templates: {
            handler() {
                this.updateQsid();
                this.getAccessData();
                this.initializeInputs();
                this.getAnswers();
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        async getAccessData() {
            this.directoryTreeData = _.cloneDeep(this.answerBankService.getDirectoryTreeData());
            const res = await this.answerBankService.getAnswerBankAccess();
            console.log('access', res.data.data);
            this.updateDirectoryTreeAccess(res.data.data);
        },
        updateDirectoryTreeAccess(accessData) {
            const updateTree = (tree) => {
                return tree.map((node) => {
                    const newNode = { ...node };
                    const matchingAccess = _.find(accessData, (access) => access.full_name === node.key);

                    newNode.isActive = !!matchingAccess;

                    if (newNode.isActive) {
                        newNode.icon = 'pi pi-fw pi-star-fill';
                    }

                    if (newNode.data == localStorage.getItem('qsid')) {
                        this.checked = newNode.isActive;
                    }

                    if (node.children) {
                        newNode.children = updateTree(node.children);
                    }

                    return newNode;
                });
            };

            this.directoryTreeData = updateTree(this.directoryTreeData);
            console.log('directoryTreeData', this.directoryTreeData);
        },
        async onCheckedClick() {
            const payload = {
                qsid: parseInt(localStorage.getItem('qsid')),
                access: this.checked,
            };
            const response = await this.answerBankService.postAnswerBankAccess(payload);
            this.updateDirectoryTreeAccess(response.data.data);
        },
        updateQsid() {
            const storedQsid = localStorage.getItem('qsid');
            this.qsid = storedQsid ? JSON.parse(storedQsid) : null;
        },
        directoryChanged(event) {
            console.log('Selected value:', event);

            const selectedKey = Object.keys(event)[0];

            const findNestedItem = (items) => {
                for (let item of items) {
                    if (item.key === selectedKey) {
                        return item;
                    }
                    if (item.children) {
                        const found = findNestedItem(item.children);
                        if (found) return found;
                    }
                }
                return null;
            };

            const selectedItem = findNestedItem(this.directoryTreeData);

            localStorage.setItem('qsid', JSON.stringify(selectedItem.data));
            this.updateQsid();

            this.activeDirectoryTitle = selectedKey.replaceAll(':', ' > ');
            this.getTemplateForQuestionSet();
        },

        handleValidation({ isValid, fieldName, categoryName }) {
            if (!this.fieldErrors[categoryName]) {
                this.fieldErrors[categoryName] = {};
            }
            this.fieldErrors = {
                ...this.fieldErrors,
                [categoryName]: {
                    ...this.fieldErrors[categoryName],
                    [fieldName]: isValid,
                },
            };

            // Checks if any field has validation errors
            this.hasErrors = Object.values(this.fieldErrors).every((category) =>
                Object.values(category).every((value) => value === true)
            );
        },
        initializeInputs() {
            // Convert answers into an object
            this.answers.forEach((category) => {
                category.data.forEach((answer) => {
                    this.answers[answer.id] = answer.json_value;
                });
            });

            // Initialize inputs with values from answers
            this.templates?.forEach((category) => {
                this.inputs[category.category_name] = {};
                category.data.forEach((field) => {
                    this.inputs[category.category_name][field.id] = this.answers[field.id] || '';
                });
            });
        },

        updateAnswers(updatedAnswers) {
            console.log('updatedAnswers', updatedAnswers);
            this.rootDirtyInd = true;

            this.answers = this.answers.map((category) => ({
                ...category,
                data: category.data.map((field) => {
                    if (updatedAnswers[field.id] !== undefined) {
                        return {
                            ...field,
                            json_value: updatedAnswers[field.id],
                        };
                    }
                    return field;
                }),
            }));

            // Update the inputs object
            Object.keys(updatedAnswers).forEach((fieldId) => {
                this.templates.forEach((category) => {
                    if (this.inputs[category.category_name] && this.inputs[category.category_name][fieldId] !== undefined) {
                        this.inputs[category.category_name] = {
                            ...this.inputs[category.category_name],
                            [fieldId]: updatedAnswers[fieldId],
                        };
                    }
                });
            });
        },

        scrollToEl(element, offset) {
            window.scrollBy({
                top: element.getBoundingClientRect().top - offset,
                behavior: 'smooth',
            });
        },

        onTabClick(event) {
            let labelNames = new Set();
            this.templates.forEach((item) => {
                labelNames.add(item.category_name);
            });

            if (labelNames.has(event.target.innerText)) {
                setTimeout(() => {
                    this.scrollToEl(event.target, 70);
                }, 250);
                this.sectionTrackGA(),
                    this.accordionLabel === event.target.innerText
                        ? (this.accordionLabel = null)
                        : (this.accordionLabel = event.target.innerText);
            }
        },

        sectionTrackGA() {
            const timeNow = new Date().getTime();
            if (timeNow > this.lastClicked) {
                const duration = timeNow - this.lastClicked;
                const seconds = Math.floor(duration / 1000);
                this.lastClicked = timeNow;

                let sectionTrackInfo = {
                    section_time_spend: seconds,
                    section_name: this.accordionLabel,
                    timestamp: timeNow,
                };

                if (this.accordionLabel !== null) {
                    this.$gtag.event('answerbank_section_track', sectionTrackInfo);
                }
            }
        },

        postAnswerBank(payload) {
            this.answerBankService.postAnswerBank(payload, this.isAdmin).then((res) => {
                if (res.status === 200) {
                    this.rootDirtyInd = false;
                    this.$toast.add({ severity: 'success', summary: res.data, detail: '', life: 3000 });
                } else {
                    this.$toast.add({ severity: 'error', summary: 'Error while creating data', detail: res.data, life: 3000 });
                }
            });
        },

        cancelAnswerBank() {
            window.location.reload();
        },

        saveAnswerBank() {
            console.log('this.answers', this.answers);
            this.newAnswerBankData = this.answers
                .flatMap((category) => category.data)
                .reduce((acc, answer) => {
                    let value;
                    this.templates.forEach((templateCategory) => {
                        templateCategory.data.forEach((field) => {
                            if (field.id === answer.id) {
                                if (field.field_type === 'single_select' || field.field_type === 'single_select_search') {
                                    value = answer.json_value.name;
                                } else if (field.field_type === 'boolean_select') {
                                    value = answer.json_value?.value ?? null;
                                } else if (field.field_type === 'multi_select' || field.field_type === 'multi_select_search') {
                                    if (Array.isArray(answer.json_value)) {
                                        value = answer.json_value.map((item) => item.name);
                                    } else {
                                        value = [];
                                    }
                                } else if (field.field_type === 'tree_select') {
                                    value = Object.entries(answer.json_value)
                                        .filter(([, { checked, partialChecked }]) => checked && !partialChecked)
                                        .map(([key]) => key);
                                } else {
                                    value = answer.json_value;
                                }
                            }
                        });
                    });
                    acc[answer.id] = value;
                    return acc;
                }, {});

            const fileUploadFields = this.templates
                .flatMap((category) => category.data)
                .filter((field) => field.field_type === 'file_upload');

            const fileUploadFieldIds = fileUploadFields.map((field) => field.id);

            const payload = Object.fromEntries(
                Object.entries(this.newAnswerBankData).filter(([key]) => !fileUploadFieldIds.includes(key))
            );

            const callSave = this.hasErrors;
            callSave
                ? this.postAnswers(payload)
                : this.$toast.add({
                      severity: 'error',
                      summary: 'Error while creating data',
                      detail: 'Missing Information',
                      life: 3000,
                  });
        },

        async getTemplateForQuestionSet() {
            let qsid = localStorage.getItem('qsid');
            const response = await this.answerBankService.getTemplateById(qsid);
            console.log('response', response.data);
            localStorage.setItem('template', JSON.stringify(response.data.data));
            this.templates = response.data.data.data;
        },

        async getAnswers() {
            const response = await this.answerBankService.getAnswers(localStorage.getItem('qsid'));

            if (response?.status === 200) {
                //console.log('response.data.data.data', response.data.data.data);
                this.answers = response.data.data.data.map((category) => {
                    return {
                        ...category,
                        data: category.data.map((answer) => {
                            let modifiedAnswer = { ...answer };
                            this.templates?.forEach((templateCategory) => {
                                templateCategory.data.forEach((field) => {
                                    if (field.id === answer.id) {
                                        if (field.field_type === 'single_select' || field.field_type === 'single_select_search') {
                                            modifiedAnswer.json_value = { name: answer.json_value };
                                        } else if (field.field_type === 'boolean_select') {
                                            modifiedAnswer.json_value =
                                                answer.json_value === 'true' || answer.json_value === true
                                                    ? { name: 'Yes', value: true }
                                                    : answer.json_value === 'false' || answer.json_value === false
                                                    ? { name: 'No', value: false }
                                                    : null;
                                            //console.log("==> ", answer.json_value, modifiedAnswer.json_value);
                                        } else if (
                                            field.field_type === 'multi_select' ||
                                            field.field_type === 'multi_select_search'
                                        ) {
                                            if (Array.isArray(answer.json_value)) {
                                                modifiedAnswer.json_value = answer.json_value.map((value) => ({ name: value }));
                                            }
                                        } else if (field.field_type === 'tree_select') {
                                            const optionsObj = {};
                                            answer.json_value?.forEach((sl) => {
                                                optionsObj[sl] = { checked: true, partialChecked: false };
                                            });
                                            modifiedAnswer.json_value = optionsObj;
                                        } else if (
                                            field.field_type === 'number' ||
                                            field.field_type === 'short_text' ||
                                            field.field_type === 'decimal'
                                        ) {
                                            modifiedAnswer.json_value = answer.json_value === 'null' ? null : answer.json_value;
                                        } else {
                                            modifiedAnswer.json_value = answer.json_value === 'null' ? null : answer.json_value;
                                        }
                                    }
                                });
                            });
                            return modifiedAnswer;
                        }),
                    };
                });
            }
        },

        postAnswers(payload) {
            this.answerBankService.postAnswers(payload, localStorage.getItem('qsid')).then((res) => {
                if (res.status === 200) {
                    this.$toast.add({ severity: 'success', summary: res.data.data, detail: '', life: 3000 });
                } else {
                    this.$toast.add({ severity: 'error', summary: 'Error while creating data', detail: res.data, life: 3000 });
                }
            });
        },
    },
};
</script>

<style scoped lang="scss">
.directoryTitle {
    font-size: 1.5rem;
    margin-left: 1rem;
}

::v-deep(.p-accordion-header) {
    font-size: large;
}

::v-deep(.p-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link),
::v-deep(.p-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link):hover {
    background-color: rgb(228, 228, 228);
    font-weight: 500;
    margin-top: 1rem;
}

::v-deep(.p-button) {
    margin: 0 0 1.25rem 1.25rem;
    width: 8rem !important;
    background: #00796b !important;
    border: 2px solid #00796b !important;
}

::v-deep(.p-button.p-button-outlined) {
    background-color: transparent !important;
    color: #00796b !important;
    border: 1px solid;
}

.profile-title {
    font-size: 2rem;
    font-weight: 500;
    margin-left: 1rem;
}

.radio-button-container {
    margin-top: 0.5rem;
    margin-left: 1rem;
    display: flex;
    align-items: center;
}

.field-radiobutton {
    display: flex;
    align-items: center;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
}

.field-radiobutton label {
    margin-left: 0.5rem;
}
</style>
