<template>
   <DxDataGrid id="grid" ref="grid" :data-source="partnerData" key-expr="account_code" :show-borders="true"
      :column-width="200" :column-min-width="100" column-resizing-mode="widget" :allow-column-resizing="true"
      :columns="gridColumns" :hover-state-enabled="true" :row-alternation-enabled="true" :allow-column-reordering="true"
      @exporting="onExporting">

      <DxColumnChooser :enabled="true" :mode="columnChooserMode" :width="400" :height="725"
         title="Column Chooser (please wait)">
         <DxPosition my="right top" at="right bottom" of=".dx-datagrid-column-chooser-button" />

         <DxColumnChooserSearch :enabled="true" :editor-options="{ placeholder: 'Search column' }" />
         <DxColumnChooserSelection :allow-select-all="true" :select-by-click="false" :recursive="true" />
      </DxColumnChooser>

      <DxColumnFixing :enabled="true" />
      <DxFilterRow :visible="true" />
      <DxFilterPanel :visible="true" />
      <DxFilterBuilderPopup :width="800" :height="725" title="Partner Matching Query Builder" />

      <DxScrolling mode="standard" />
      <DxPaging :page-size="50" />
      <DxPager :show-page-sizes="true" :allowed-page-sizes="pageSizes" display-mode="full"
         :show-page-size-selector="true" />
      <DxColumnFixing :enabled="true" />
      <DxExport :enabled="true" />

      <DxHeaderFilter :visible="true">
         <DxSearch :enabled="true" />
      </DxHeaderFilter>

      <DxGroupPanel :visible="true" />
      <DxGrouping :auto-expand-all="false" />

      <DxStateStoring :enabled="true" type="custom" :custom-load="loadState" :custom-save="saveState" />

   </DxDataGrid>
</template>
<script>

import {
   DxDataGrid,
   DxHeaderFilter,
   DxSearch,
   DxFilterRow,
   DxFilterPanel,
   DxFilterBuilderPopup,
   DxScrolling,
   DxPaging,
   DxPager,
   DxColumnFixing,
   DxColumnChooser,
   DxPosition,
   DxColumnChooserSearch,
   DxColumnChooserSelection,
   DxExport,
   DxGroupPanel,
   DxGrouping,
   DxStateStoring
} from 'devextreme-vue/data-grid';


import { getPartnerData, getColumnStructure } from "./partnerMatchingService.js";

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import 'devextreme/dist/css/dx.light.css';

export default {
   components: {
      DxDataGrid,
      //DxColumn,
      DxHeaderFilter,
      DxSearch,
      DxFilterPanel,
      DxFilterRow,
      DxFilterBuilderPopup,
      DxScrolling,
      DxPaging,
      DxPager,
      DxColumnFixing,
      DxColumnChooser,
      DxPosition,
      DxColumnChooserSearch,
      DxColumnChooserSelection,
      DxExport,
      DxGroupPanel,
      DxGrouping,
      DxStateStoring
   },
   data() {
      return {
         userDetails: null,
         partnerData: null,
         gridColumns: [],
         pageSizes: [10, 20, 50, 100, 'all'],
         columnResizingMode: 'widget',
         columnChooserMode: 'select',
      };
   },
   computed: {
      //   grid() {
      //     return this.$refs[this.gridRefName].instance;
      //   },
   },

   created() {
      console.log("entering created");
      console.log("exiting created");
      this.userDetails = JSON.parse(localStorage.getItem('pro_marketplaceUser'));

   },

   async mounted() {
      console.log("entering mounted");

      let columnStructure = getColumnStructure();

      for (let i = 0; i < columnStructure.length; i++) {
         let colData = columnStructure[i];

         let newCol = {};
         newCol.caption = colData.caption;

         if (colData.visible != undefined && colData.visible == false) { newCol.visible = false; }
         if (colData.fixed != undefined) { newCol.fixed = colData.fixed; }
         if (colData.allowHiding != undefined) { newCol.allowHiding = colData.allowHiding; }
         if (colData.fixedPosition != undefined) { newCol.fixedPosition = colData.fixedPosition; }

         if (colData.columns) {
            newCol.columns = [];
            for (let j = 0; j < colData.columns.length; j++) {
               let subColData = colData.columns[j]

               let newSubCol = {};
               newSubCol.caption = subColData.caption;
               newSubCol.dataField = subColData.dataField;
               newSubCol.dataType = subColData.dataType;

               newCol.columns.push(newSubCol);
            }
         }
         else {
            newCol.dataField = colData.dataField;
            newCol.dataType = colData.dataType;
         }

         this.gridColumns.push(newCol);
      }

      // console.log("gridColumns", this.gridColumns);
      // console.log("Now getting partner data");

      this.partnerData = this.userDetails.value.userRole.roleKey == "channel:business:admin" ? await getPartnerData(this.userDetails.value.subnetworkId) : await getPartnerData();
      //console.log("partnerData", this.partnerData);
      //this.columnResizingMode = 'widget';



      console.log("exiting mounted");
   },

   methods: {
      loadState() {
         let state = localStorage.getItem("acx-partner-matching-grid-config");
         if (state) { state = JSON.parse(state); } else { state = null; }
         return state;
      },
      saveState(state) {
         localStorage.setItem("acx-partner-matching-grid-config", JSON.stringify(state));
      },


      onExporting(e) {
         const workbook = new Workbook();
         const worksheet = workbook.addWorksheet('Employees');

         exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
         }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
               saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'ArenaCX-Partner-Match.xlsx');
            });
         });
         e.cancel = true;
      },

   },

};
</script>
<style scoped>
#grid {
   height: 1000px;
}
</style>