<template>
    <p class="field-title">{{ label }}</p>
    <MultiSelect :modelValue="selectedValue" @update:modelValue="handleInput" :options="options" optionLabel="name"
        :placeholder="placeholder" display="chip" filter :disabled="isChannel" />
    <p v-if="v$.selectedValue.$error" class="error-msg">{{ error_message }}</p>
</template>

<script>
import { ref, watch, computed } from 'vue';
import MultiSelect from 'primevue/multiselect';
import { useVuelidate } from '@vuelidate/core';
import { minLength, required } from '@vuelidate/validators';
import { getIsChannel } from '../../service/common';

export default {
    name: 'MultiSelectDropdownSearchComponent',
    components: {
        MultiSelect,
    },
    data() {
        return {
            isChannel: false,
        };
    },
    created() {
        this.isChannel = getIsChannel()
    },
    props: {
        label: {
            type: String,
            required: true,
        },
        modelValue: {
            type: Array,
            default: () => [],
        },
        placeholder: {
            type: String,
            default: '',
        },
        validation_rules: {
            type: Object,
            default: () => ({}),
        },
        error_message: {
            type: String,
        },
        options: {
            type: Array,
            default: () => [],
        },
    },

    setup(props, { emit }) {
        const selectedValue = ref(props.modelValue);

        const rules = computed(() => {
            const validationRules = {};
            if (props.validation_rules.minLength) {
                validationRules.minLength = minLength(props.validation_rules.minLength);
                //validationRules.required = required;
            }
            return {
                selectedValue: validationRules,
            };
        });

        const v$ = useVuelidate(rules, { selectedValue });

        watch(
            () => props.modelValue,
            (newVal) => {
                selectedValue.value = newVal;
                v$.value.$touch();
                if (!v$.value.selectedValue.$error) {
                    emit('isValid', true);
                } else {
                    emit('isValid', false);
                }
            }
        );

        const handleInput = (newValue) => {
            selectedValue.value = newValue;
            emit('update:modelValue', newValue);
            v$.value.$touch();
            if (!v$.value.selectedValue.$error) {
                emit('isValid', true);
            } else {
                emit('isValid', false);
            }
        };

        return { selectedValue, v$, handleInput };
    },
};
</script>
