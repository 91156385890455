<template>
    <div>
        <div class="p-d-flex p-jc-between">
            <p class="profile-title">Question Library</p>
            <!-- <div >
                <Button label="Cancel" class="p-button p-component p-button-outlined button" @click="cancelAnswerBank" />
                <Button label="Save" class="p-button p-component button" @click="saveAnswerBank" />
            </div> -->
        </div>

        <div class="card flex justify-center">
            <TreeSelect v-model="activeDirectory" :options="directoryTreeData"
                placeholder="Select the Answer Bank to review" style="width: 50rem" inputStyle="{fontSize: '1.2rem'}"
                fluid="true" selectionMode="single" @change="directoryChanged" />
            <span class="directoryTitle">{{ activeDirectoryTitle }}</span>
        </div>

        <div class="p-d-flex">
            <div class="card left-container p-mx-3 p-my-2">
                <div class="p-d-flex p-jc-between">
                    <p class="title">Categories</p>
                    <Button v-if="qsid" label="Add Section" class="p-button p-component button"
                        @click="openAddCategoryModal" />
                    <Button label="Save" class="p-button p-component button" @click="saveCategoriesOrder" />
                </div>
                <DataTable :value="templates" @rowReorder="onCategoryRowReorder" v-model:selection="selectedProduct"
                    selectionMode="single" @rowSelect="onRowSelect" dataKey="category_id" tableStyle="min-width: 100px">
                    <Column rowReorder headerStyle="width: 5rem" :reorderableColumn="false" />
                    <Column key="category_id" field="category_name" header="Category Name" />
                    <Column headerStyle="width: 3rem">
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" class="p-button-outlined"
                                @click="openCategoryEditModal(slotProps.data)" />
                        </template>
                    </Column>
                </DataTable>
            </div>

            <div class="card right-container p-mx-3 p-my-2">
                <div class="p-d-flex p-jc-between">
                    <p class="title">Questions</p>
                    <div>
                        <Button v-if="selectedProduct" label="Add Question" class="p-button p-component button"
                            @click="openAddQuestionModal" :disabled="!selectedProduct" />
                        <!-- <Button label="Cancel" class="p-button p-component p-button-outlined button" @click="cancelAnswerBank" /> -->
                        <Button label="Save" class="p-button p-component button" @click="saveQuestionsOrder" />
                    </div>
                </div>
                <DataTable :value="questionSets" @rowReorder="onQuestionRowReorder" dataKey="id"
                    tableStyle="min-width: 100px">
                    <Column rowReorder headerStyle="width: 5rem" />
                    <Column key="id" field="display_name" header="Question" />
                    <Column headerStyle="width: 3rem">
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" class="p-button-outlined"
                                @click="openQuestionEditModal(slotProps.data)" />
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>

        <Dialog v-model:visible="displayAddCategoryModal" :style="{ width: '50vw' }" header="Add Category"
            :modal="true">
            <div class="p-fluid">
                <div class="p-field">
                    <label for="category_name">Category Name</label>
                    <InputText id="category_name" v-model="newCategory.category_name" />
                </div>
            </div>
            <template #footer>
                <Button label="Cancel" icon="pi pi-times" @click="closeAddCategoryModal" class="p-button-text" />
                <Button label="Save" icon="pi pi-check" @click="addCategory" class="p-button " />
            </template>
        </Dialog>

        <Dialog v-model:visible="displayCategoryModal" :style="{ width: '50vw' }" header="Edit Category" :modal="true">
            <div class="p-fluid">
                <div class="p-field">
                    <label for="category_name">Category Name</label>
                    <InputText id="category_name" v-model="editingCategory.category_name" />
                </div>
            </div>
            <template #footer>
                <Button label="Cancel" icon="pi pi-times" @click="closeCategoryModal" class="p-button-text" />
                <Button label="Save" icon="pi pi-check" @click="saveCategory" autofocus />
            </template>
        </Dialog>


        <Dialog v-model:visible="displayAddQuestionModal" :style="{ width: '50vw' }" header="Add Question"
            :modal="true">
            <div class="p-fluid">
                <div class="p-field">
                    <label for="display_name">Question Text *</label>
                    <InputText id="display_name" v-model="newQuestion.display_name" @input="updateFieldName" />
                </div>
                <div class="p-grid">
                    <div class="p-col-6">
                        <div class="p-field">
                            <label for="field_type">Field Type *</label>
                            <Dropdown id="field_type" v-model="newQuestion.field_type" :options="fieldTypeOptions"
                                optionLabel="name" optionValue="value" placeholder="Select Field Type" />
                        </div>
                    </div>

                </div>
                <div class="p-field">
                    <label for="placeholder">Placeholder that will appear to customers in the dialog before they being typing an answer</label>
                    <InputText id="placeholder" v-model="newQuestion.placeholder" />
                </div>
                <div class="p-field">
                    <label for="description">Long description that will appear when browsing questions for a new RFP or RFP Template</label>
                    <InputText id="description" v-model="newQuestion.description" />
                </div>
                <div v-if="isSelectFieldType(newQuestion.field_type)" class="p-field">
                    <label for="dropdown_options">Dropdown Options</label>
                    <div class="optionsIndicator" @click="toggleOptionListEditor($event, newQuestion)">
                        {{ newQuestion.dropdown_options?.length || 0 }} options
                    </div>
                </div>
                <div class="p-field">
                    <label for="error_message">Error Message</label>
                    <InputText id="error_message" v-model="newQuestion.error_message" />
                </div>
                <div class="flex align-items-center">
                    <Checkbox id="attachment_required" v-model="newQuestion.attachment_required" :binary="true" />
                    <label for="attachment_required" class="ml-5">Attachment Required</label>
                </div>
            </div>
            <template #footer>
                <Button label="Cancel" icon="pi pi-times" @click="closeAddQuestionModal" class="p-button-text" />
                <Button label="Save" icon="pi pi-check" @click="addQuestion" class="p-button" />
            </template>
        </Dialog>

        <Dialog v-model:visible="displayQuestionModal" :style="{ width: '50vw' }" header="Edit Question" :modal="true">
            <div class="p-fluid">
                <div class="p-field">
                    <label for="display_name">Display Name</label>
                    <InputText disabled id="display_name" v-model="editingQuestion.display_name"
                        @input="updateFieldName" />
                </div>
                <div class="p-field">
                    <label for="display_name">Question Text</label>
                    <InputText id="display_name" v-model="editingQuestion.question_text" />
                </div>
                <div class="p-grid">
                    <div class="p-col-6">
                        <div class="p-field">
                            <label for="field_type">Field Type</label>
                            <Dropdown id="field_type" v-model="editingQuestion.field_type" :options="fieldTypeOptions"
                                optionLabel="name" optionValue="value" placeholder="Select Field Type"
                                :disabled="true" />
                        </div>
                    </div>
                </div>
                <div class="p-field">
                    <label for="placeholder">Placeholder</label>
                    <InputText id="placeholder" v-model="editingQuestion.placeholder" />
                </div>
                <div class="p-field">
                    <label for="description">Description</label>
                    <InputText id="description" v-model="editingQuestion.description" />
                </div>
                <div v-if="isSelectFieldType(editingQuestion.field_type)" class="p-field">
                    <label for="dropdown_options">Dropdown Options</label>
                    <div class="optionsIndicator" @click="toggleOptionListEditor($event, editingQuestion)">
                        {{ editingQuestion.dropdown_options?.length || 0 }} options
                    </div>
                </div>
                <div class="p-field">
                    <label for="error_message">Error Message</label>
                    <InputText id="error_message" v-model="editingQuestion.error_message" />
                </div>
                <div class="flex align-items-center">
                    <Checkbox id="attachment_required" v-model="editingQuestion.attachment_required" :binary="true" />
                    <label for="attachment_required" class="ml-5">Attachment Required</label>
                </div>
            </div>
            <template #footer>
                <Button label="Cancel" icon="pi pi-times" @click="closeQuestionModal" class="p-button-text" />
                <Button label="Save" icon="pi pi-check" @click="saveQuestion" autofocus />
            </template>
        </Dialog>
    </div>
    <Toast />
    <ConfirmDialog />

    <OverlayPanel ref="opOptionsEditor" :showCloseIcon="true">
        <div class="optionEditorStyles">
            <span>Edit Options - One per line</span>
            <Textarea v-model="activeOptionsList" rows="10" cols="30" @change="setOptionList" />
        </div>
    </OverlayPanel>
</template>

<script>
import AnswerBankService from '../service/AnswerBankService';
import QuestionBankService from '../service/QuestionBankService';
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import OverlayPanel from 'primevue/overlaypanel';
import ConfirmDialog from 'primevue/confirmdialog';
import Toast from 'primevue/toast';


export default {
    name: 'QuestionBank',
    components: {
        Toast,
        ConfirmDialog,
        OverlayPanel
    },
    setup() {
        const $confirm = useConfirm();
        const $toast = useToast();
        return {
            $confirm,
            $toast
        }
    },
    data() {
        return {
            activeDirectory: null,
            activeDirectoryTitle: null,
            directoryTreeData: null,
            qsid: null,
            templates: [],
            questionSets: [],
            displayCategoryModal: false,
            editingCategory: {},
            displayQuestionModal: false,
            displayAddCategoryModal: false,
            displayAddQuestionModal: false,
            editingQuestion: {},
            dropdownOptionsJson: '',
            newDropdownOptionsJson: '',
            newCategory: {
                category_name: '',
                category_position: 0,
                data: []
            },
            newQuestion: {
                field_name: '',
                display_name: '',
                question_text: '',
                field_type: '',
                data_type: '',
                placeholder: '',
                description: '',
                dropdown_options: [],
                error_message: '',
                attachment_required: false,
                field_position: 0
            },

            activeOptionsList: '',
            activeQuestion: null,

            fieldTypeOptions: [
                { name: 'Short Text', value: 'short_text', dataType: 'Short Text' },
                { name: 'Long Text', value: 'long_text', dataType: 'Long Text' },
                { name: 'Number', value: 'number', dataType: 'Numeric' },
                { name: 'Decimal', value: 'decimal', dataType: 'Decimal' },
                { name: 'Single Select', value: 'single_select', dataType: 'Single select drop down' },
                { name: 'Multi Select', value: 'multi_select', dataType: 'Multi select drop down' },
                { name: 'Multi Select Search', value: 'multi_select_search', dataType: 'Multi select drop down' },
                { name: 'Single Select Search', value: 'single_select_search', dataType: 'Single select drop down' },
                { name: 'Tree Select', value: 'tree_select', dataType: 'Single select drop down' },
                { name: 'File Upload', value: 'file_upload', dataType: 'File Upload' },
                { name: 'Date', value: 'date', dataType: 'Date' },
                { name: 'Boolean Select', value: 'boolean_select', dataType: 'Single select drop down' },
            ],
        };
    },
    created() {
        // this.questionBankService = new QuestionBankService();
        this.answerBankService = new AnswerBankService();
        this.directoryTreeData = this.answerBankService.getDirectoryTreeData();
        this.questionBankService = new QuestionBankService();
    },
    async mounted() {
        localStorage.removeItem('qsid');
        localStorage.removeItem('categoryId');
        this.updateQsid();
    },
    methods: {

        //  helper method to get data type from field type
        getDataTypeFromFieldType(fieldType) {
            const fieldTypeOption = this.fieldTypeOptions.find(option => option.value === fieldType);
            return fieldTypeOption ? fieldTypeOption.dataType : null;
        },

        // Toggle Option List Editor
        toggleOptionListEditor(event, question) {
            this.activeQuestion = question;
            // Convert dropdown options to newline-separated string
            this.activeOptionsList = question.dropdown_options
                ? question.dropdown_options
                    .filter(o => !o.isDeleted)
                    .map(o => o.name)
                    .join('\n')
                : '';

            this.$refs.opOptionsEditor.toggle(event);
        },

        // Set Option List
        setOptionList() {
            if (!this.activeQuestion) return;
            try {
                // Split by newline and filter out empty lines
                const options = this.activeOptionsList
                    .split('\n')
                    .map(opt => opt.trim())
                    .filter(opt => opt !== '');
                // Check for duplicate options
                const uniqueOptions = new Set(options);
                if (uniqueOptions.size !== options.length) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Validation Error',
                        detail: 'Duplicate options are not allowed',
                        life: 3000
                    });
                    return;
                }
                // Convert to required format
                const formattedOptions = options.map(opt => ({
                    name: opt,
                    value: opt.toLowerCase().replace(/[^a-z0-9]/g, '_')
                }));
                // Update the active question's dropdown options
                this.activeQuestion.dropdown_options = formattedOptions;
                this.$toast.add({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Options updated successfully',
                    life: 3000
                });
            } catch (error) {
                console.error('Error setting options:', error);
                this.$toast.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Failed to update options',
                    life: 3000
                });
            }
        },

        // Function to check if the field type is a select type
        isSelectFieldType(fieldType) {
            const selectTypes = [
                'single_select',
                'multi_select',
                'multi_select_search',
                'tree_select',
                'single_select_search',
                'boolean_select'
            ];
            return selectTypes.includes(fieldType);
        },

        // Check for unique field name across all questions
        isFieldNameUnique(fieldName) {
            // Check across all categories and their questions
            return !this.templates.some(category =>
                category.data.some(question =>
                    question.field_name.toLowerCase() === fieldName.toLowerCase()
                )
            );
        },

        // Update Field Name
        updateFieldName() {
            if (this.newQuestion.display_name) {
                // Convert to lowercase, replace spaces with underscores, and remove special characters
                this.newQuestion.field_name = this.newQuestion.display_name
                    .toLowerCase()
                    .replace(/[^a-z0-9\s]/g, '') // Remove special characters
                    .replace(/\s+/g, '_')        // Replace spaces with underscores
                    .replace(/_+/g, '_');        // Replace multiple underscores with single underscore
            } else {
                this.newQuestion.field_name = '';
            }
        },

        // Open Category Modal
        openAddCategoryModal() {
            this.displayAddCategoryModal = true;
        },

        // Close Category Modal  
        closeAddCategoryModal() {
            this.displayAddCategoryModal = false;
            this.newCategory = {
                category_name: '',
                category_position: 0,
                data: []
            };
        },

        // Add Category
        addCategory() {
            const newCategoryData = {
                ...this.newCategory,
                category_position: this.templates.length + 1,
                question_set_id: this.qsid,
                data: [], // Initialize empty data array for questions
                category_id: Date.now() // Generate a temporary unique ID
            };

            // Store the new category in localStorage
            let storedCategories = JSON.parse(localStorage.getItem('categories')) || [];
            storedCategories.push(newCategoryData);
            localStorage.setItem('categories', JSON.stringify(storedCategories));

            // Update the templates array and automatically select the new category
            this.templates.push(newCategoryData);
            this.selectedProduct = newCategoryData;
            this.questionSets = newCategoryData.data;

            // Close the category modal and open the question modal
            this.closeAddCategoryModal();
            this.$toast.add({
                severity: 'info',
                summary: 'New Section Added',
                detail: 'Please add at least one question to this section',
                life: 3000
            });
            this.openAddQuestionModal();
        },

        // Open Add Question Modal
        openAddQuestionModal() {
            if (!this.selectedProduct) {
                this.$toast.add({
                    severity: 'warn',
                    summary: 'Warning',
                    detail: 'Please select a section first',
                    life: 3000
                });
                return;
            }
            this.displayAddQuestionModal = true;
        },

        // Close Add Question Modal
        closeAddQuestionModal() {
            // Check if this is a new category with no questions
            if (this.selectedProduct && this.selectedProduct.data.length === 0) {
                this.$confirm.require({
                    message: 'At least one question is required for this section. Do you want to continue adding a question?',
                    header: 'Question Required',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => {
                        // Keep the modal open
                        return;
                    },
                    reject: () => {
                        // Remove the category if user doesn't want to add a question
                        this.removeEmptyCategory();
                    }
                });
                return;
            }

            this.displayAddQuestionModal = false;
            this.newQuestion = {
                field_name: '',
                display_name: '',
                question_text: '',
                field_type: '',
                data_type: '',
                placeholder: '',
                description: '',
                dropdown_options: [],
                error_message: '',
                attachment_required: false,
                field_position: 0
            };
            this.newDropdownOptionsJson = '';
        },

        // Remove Empty Category
        removeEmptyCategory() {
            // Remove the category from templates array
            this.templates = this.templates.filter(t => t.category_id !== this.selectedProduct.category_id);
            // Update localStorage
            let storedCategories = JSON.parse(localStorage.getItem('categories')) || [];
            storedCategories = storedCategories.filter(c => c.category_id !== this.selectedProduct.category_id);
            localStorage.setItem('categories', JSON.stringify(storedCategories));

            // Reset selection
            this.selectedProduct = null;
            this.questionSets = [];

            this.displayAddQuestionModal = false;
        },

        // Add Question
        async addQuestion() {
            // Add validation checks
            if (!this.newQuestion.display_name || !this.newQuestion.field_type) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Validation Error',
                    detail: 'Display Name and Field Type are required',
                    life: 3000
                });
                return;
            }
            try {
                // Generate field_name from display_name
                const generatedFieldName = this.newQuestion.display_name
                    .toLowerCase()
                    .replace(/[^a-z0-9\s]/g, '') // Remove special characters
                    .replace(/\s+/g, '_')        // Replace spaces with underscores
                    .replace(/_+/g, '_');        // Replace multiple underscores with single underscore

                // Check for unique field name
                if (!this.isFieldNameUnique(generatedFieldName)) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Validation Error',
                        detail: 'A question with similar name already exists. Please use a different name.',
                        life: 3000
                    });
                    return;
                }

                const newQuestionData = {
                    ...this.newQuestion,
                    id: Date.now(),
                    field_name: generatedFieldName,
                    question_text: this.newQuestion.display_name,
                    data_type: this.getDataTypeFromFieldType(this.newQuestion.field_type),
                    field_position: this.questionSets.length + 1,
                    dropdown_options: this.newQuestion.dropdown_options?.length === 0 ? null : this.newQuestion.dropdown_options,
                    directory_name: this.activeDirectoryTitle ? this.activeDirectoryTitle.toLowerCase() : '',
                };

                // Check if this is the first question for the section
                const isFirstQuestion = this.selectedProduct && this.selectedProduct.data.length === 0;
                if (isFirstQuestion) {
                    // Prepare category data
                    const categoryData = {
                        ...this.selectedProduct,
                        question_set_id: this.qsid,
                    };

                    // Make API call to save both category and question
                    const response = await this.questionBankService.addCategoryWithQuestion(
                        {
                            categoryData: categoryData,
                            questionData: newQuestionData,
                        }

                    );

                    if (response.data) {
                        console.log("Response from addCategoryWithQuestion", response.data);
                        // Remove the category from localStorage after successful save
                        localStorage.removeItem('categories');


                        // Create a properly structured category object
                        const updatedCategory = {
                            category_id: response.data.data.category.id,
                            category_name: response.data.data.category.name,
                            category_position: response.data.data.category.position,
                            data: [newQuestionData], // Initialize with the new question
                            question_set_id: response.data.data.category.question_set_id
                        };

                        // Update newQuestionData with the server-generated ID
                        newQuestionData.id = response.data.data.question.id;


                        const tempCategory = this.selectedProduct;
                        // Update the state
                        this.selectedProduct = updatedCategory;
                        this.questionSets = [newQuestionData];



                        // Update templates array
                        const templateIndex = this.templates.findIndex(t => t?.category_id === tempCategory?.category_id);
                        if (templateIndex !== -1) {
                            this.templates[templateIndex] = this?.selectedProduct;
                        }

                        this.$toast.add({
                            severity: 'success',
                            summary: 'Category Created',
                            detail: 'New category and its first question have been successfully saved',
                            life: 3000
                        });
                    }
                } else {
                    // Make API call to save just the question
                    const response = await this.questionBankService.addQuestion(
                        {
                            ...newQuestionData,
                            category_id: this.selectedProduct.category_id,
                            question_set_id: this.qsid,
                            category_position: this.selectedProduct.category_position,

                        }
                    );

                    if (response.data) {
                        // Update with data from server
                        newQuestionData.id = response.data.data.id;

                        // Update UI
                        this.questionSets.push(newQuestionData);
                        this.selectedProduct.data = this.questionSets;

                        // Update templates array
                        const categoryIndex = this.templates.findIndex(t => t.category_id === this.selectedProduct.category_id);
                        if (categoryIndex !== -1) {
                            this.templates[categoryIndex] = this.selectedProduct;
                        }

                        this.$toast.add({
                            severity: 'success',
                            summary: 'Question Added',
                            detail: 'Question has been successfully saved',
                            life: 3000
                        });
                    }
                }

                this.closeAddQuestionModal();

            } catch (error) {
                console.error('Error saving data:', error);
                this.$toast.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Failed to save. Please try again.',
                    life: 3000
                });

                // Optionally show more specific error messages
                if (error.response?.data?.message) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Error Details',
                        detail: error.response.data.message,
                        life: 5000
                    });
                }
            }
        },

        // Directory Changed
        directoryChanged(event) {
            console.log('Selected value:', event);

            const selectedKey = Object.keys(event)[0];

            const findNestedItem = (items) => {
                for (let item of items) {
                    if (item.key === selectedKey) {
                        return item;
                    }
                    if (item.children) {
                        const found = findNestedItem(item.children);
                        if (found) return found;
                    }
                }
                return null;
            };

            const selectedItem = findNestedItem(this.directoryTreeData);

            localStorage.setItem('qsid', JSON.stringify(selectedItem.data));
            this.updateQsid();

            this.activeDirectoryTitle = selectedKey.replaceAll(':', ' > ');
            this.getTemplateForQuestionSet();
        },

        // Update Qsid
        updateQsid() {
            const storedQsid = localStorage.getItem('qsid');
            this.qsid = storedQsid ? JSON.parse(storedQsid) : null;
        },

        // Get Template For Question Set
        async getTemplateForQuestionSet() {
            let qsid = localStorage.getItem('qsid');
            const response = await this.answerBankService.getTemplateById(qsid);
            console.log('response', response.data);
            localStorage.setItem('template', JSON.stringify(response.data.data));
            this.templates = response.data.data.data;
        },

        // On Category Row Reorder
        onCategoryRowReorder(event) {
            console.log('event', event);
            this.templates = event.value;

            // Update the category_position for each category
            this.templates.forEach((category, index) => {
                category.category_position = index + 1;
            });
        },

        // On Question Row Reorder
        onQuestionRowReorder(event) {
            console.log('event', event);
            this.questionSets = event.value;

            // Update the field_position for each question
            this.questionSets.forEach((question, index) => {
                question.field_position = index + 1;
            });
        },

        onRowSelect(event) {
            console.log('onRowSelectEvent', event);
            console.log('questions', event.data.data);
            localStorage.setItem('categoryId', event.data.category_id);
            this.questionSets = event.data.data;
        },

        // Open Question Edit Modal
        openQuestionEditModal(question) {
            this.editingQuestion = { ...question };
            this.dropdownOptionsJson = JSON.stringify(question.dropdown_options, null, 2);
            this.displayQuestionModal = true;
        },

        // Close Question Edit Modal
        closeQuestionModal() {
            this.displayQuestionModal = false;
            this.editingQuestion = {};
        },

        // Update Question
        saveQuestion() {

            // Add validation checks
            if (!this.editingQuestion.display_name) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Validation Error',
                    detail: 'Display Name, Field Type and Data Type are required',
                    life: 3000
                });
                return;
            }

            try {
                // Update the questionSets array with the edited question
                const index = this.questionSets.findIndex((q) => q.id === this.editingQuestion.id);
                if (index !== -1) {
                    this.questionSets[index] = { ...this.editingQuestion };
                }

                // Make API call with updated question data
                this.questionBankService
                    .updateQuestion(this.editingQuestion, this.qsid, localStorage.getItem('categoryId'))
                    .then((res) => {
                        console.log('res', res);
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Success',
                            detail: 'Question updated successfully',
                            life: 3000
                        });
                        this.closeQuestionModal();
                    })
                    .catch((error) => {
                        console.error('Error updating question:', error);
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Error',
                            detail: error.response?.data?.message || 'Failed to update question',
                            life: 3000
                        });
                    });
            } catch (error) {
                console.error('Error saving question:', error);
                // Error toast is already shown by updateDropdownOptions
            }
        },

        openCategoryEditModal(category) {
            this.editingCategory = { ...category };
            this.displayCategoryModal = true;
        },
        closeCategoryModal() {
            this.displayCategoryModal = false;
            this.editingCategory = {};
        },
        saveCategory() {
            // Implement the logic to save the edited category
            console.log('Saving category:', this.editingCategory);
            // Update the templates array with the edited category
            const index = this.templates.findIndex((c) => c.category_id === this.editingCategory.category_id);
            if (index !== -1) {
                this.templates[index] = { ...this.editingCategory };
            }

            this.questionBankService.updateCategory(this.editingCategory, this.qsid).then((res) => {
                console.log('res', res);
            });

            this.closeCategoryModal();
        },

        saveQuestionsOrder() {
            console.log('questionSets', this.questionSets);

            this.questionBankService
                .saveQuestionsOrder(this.questionSets, this.qsid, localStorage.getItem('categoryId'))
                .then((res) => {
                    console.log('res', res);
                });
        },

        saveCategoriesOrder() {
            console.log('templates', this.templates);
            this.questionBankService.saveCategoriesOrder(this.templates).then((res) => {
                console.log('res', res);
            });
        },

        updateDropdownOptions() {
            try {
                this.editingQuestion.dropdown_options = JSON.parse(this.dropdownOptionsJson);
            } catch (error) {
                // Handle parsing error (e.g., show an error message to the user)
                console.error('Invalid JSON for dropdown options:', error);
            }
        },
    },
};
</script>

<style scoped lang="scss">
.profile-title {
    font-size: 1.75rem;
    font-weight: 500;
    margin-left: 1rem;
}

.directoryTitle {
    font-size: 1.5rem;
    margin-left: 1rem;
}

.left-container {
    width: 40%;
    min-width: 300px;
}

.right-container {
    width: 60%;
    min-width: 400px;
}

.title {
    font-size: 1.25rem;
    font-weight: 500;
}

::v-deep(.p-accordion-header) {
    font-size: large;
}

::v-deep(.p-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link),
::v-deep(.p-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link):hover {
    background-color: rgb(228, 228, 228);
    font-weight: 500;
    margin-top: 1rem;
}

::v-deep(.p-button) {
    margin: 0 0 1.25rem 1.25rem;
    width: 8rem !important;
    background: #00796b !important;
    border: 2px solid #00796b !important;
}

::v-deep(.p-button.p-button-outlined) {
    background-color: transparent !important;
    color: #00796b !important;
    border: 1px solid;
}

.field-radiobutton label {
    margin-left: 0.5rem;
}

.optionsIndicator {
    padding: 0.75rem;
    border: 1px solid #ced4da;
    border-radius: 4px;
    cursor: pointer;
    background-color: #f8f9fa;
    transition: background-color 0.2s;

    &:hover {
        background-color: #e9ecef;
    }
}


.optionEditorStyles {
    padding: 1rem;

    span {
        display: block;
        margin-bottom: 0.5rem;
        font-weight: 500;
    }

    textarea {
        min-width: 300px;
    }
}

.btn-blue-color {
    background-color: #00796b !important;
    border: 2px solid #00796b !important;
}


::v-deep(.p-overlaypanel) {
    .p-overlaypanel-content {
        padding: 0;
    }
}
</style>