import axios from 'axios';
// import { useStorage } from "vue3-storage";
import CurrentUserService from '../service/CurrentUserService';
const currentUser = new CurrentUserService();

function getAxios() {
    currentUser.refreshCognitoJwt();
    let jwt = currentUser.jwt;

    //let jwt = storage.getStorageSync("jwt");

    const instance = axios.create({
        baseURL: process.env.VUE_APP_DOT_NET_BASE_URL,
        withCredentials: false,
    });

    // set common headers
    instance.defaults.headers.common['Authorization'] = jwt;
    instance.defaults.headers.common['Content-Type'] = 'application/json';

    return instance;
}

async function getUserMenuData(cognitoUsername) {
    var result = await getAxios()
        .get(`/Users/${cognitoUsername}/menuData`)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.error(err.stack);
        });

    return result;
}

//const ACCOUNT_TYPE_UNKNOWN = -1;
const ACCOUNT_TYPE_ARENACX = 0;
const ACCOUNT_TYPE_MEMBER = 1;
const ACCOUNT_TYPE_PARTNER = 2;
const ACCOUNT_TYPE_CHANNEL = 3;
const DASHBOARD_STATUS_RETIRED = 1;
const DASHBOARD_STATUS_WIP = 2; //  work-in-progress

// Add channel admin menu items
function getChannelAdminMenuItems() {
    return {
        label: 'Channel Summary',
        items: [
            new MenuItem('Home', 'pi-home', undefined, '/channel/home'),
            new MenuItem('Channel Profile', 'pi-user', undefined, '/channel/profile'),
        ],
    };
}

export default class SidebarMenuService {
    async getMenuData(cognitoUsername) {
        let userMenuData = await getUserMenuData(cognitoUsername);

        //starting to loop thru accounts

        var allAccountItems = [];

        // If this is an ArenaCX user, show all the links for all members and partners, grouped.
        // If not, just show that one user their own single Member's/Partner's links.
        if (currentUser.userAccountTypeId == ACCOUNT_TYPE_ARENACX) {
            var memberAccountData = userMenuData.accessToAccounts.filter((acct) => acct.accountTypeId == ACCOUNT_TYPE_MEMBER);
            var memberAccountItems = getAccountItems(memberAccountData, ACCOUNT_TYPE_MEMBER);
            var acxMemberItems = {};
            acxMemberItems.label = 'Members';
            acxMemberItems.icon = 'pi pi-fw pi-globe';
            acxMemberItems.items = memberAccountItems;
            allAccountItems.push(acxMemberItems);

            let partnerAccountData = userMenuData.accessToAccounts.filter((acct) => acct.accountTypeId == ACCOUNT_TYPE_PARTNER);
            let partnerAccountItems = getAccountItems(partnerAccountData, ACCOUNT_TYPE_PARTNER);
            let acxPartnerItems = {};
            acxPartnerItems.label = 'Partners';
            acxPartnerItems.icon = 'pi pi-fw pi-globe';
            acxPartnerItems.items = partnerAccountItems;
            allAccountItems.push(acxPartnerItems);
        } else if (currentUser.userAccountTypeId == ACCOUNT_TYPE_CHANNEL) {
            allAccountItems.push(getChannelAdminMenuItems());

            let partnerAccountData = userMenuData.accessToAccounts.filter((acct) => acct.accountTypeId == ACCOUNT_TYPE_PARTNER);
            let partnerAccountItems = getAccountItems(partnerAccountData, ACCOUNT_TYPE_PARTNER);
            let acxPartnerItems = {};
            acxPartnerItems.label = 'Partners';
            acxPartnerItems.icon = 'pi pi-fw pi-globe';
            acxPartnerItems.items = partnerAccountItems;
            allAccountItems.push(acxPartnerItems);
        } else {
            //var accountTypeRouterPath = userMenuData.userRole.roleKey.split(':')[0];  // e.g., "partner" from roleKey="partner:business:user"
            var userAccountItems = getAccountItems(userMenuData.accessToAccounts, currentUser.userAccountTypeId);
            var userItems = {};
            // userItems.label = 'Your Marketplace Tools';
            userItems.icon = 'pi pi-fw pi-globe';
            userItems.items = userAccountItems;
            allAccountItems.push(userItems);
        }

        return allAccountItems;
    }
}

function getAccountItems(accountData, userAccountType) {
    var accountItems = [];

    var accountTypeRouterPath = '';
    switch (userAccountType) {
        case ACCOUNT_TYPE_PARTNER:
            accountTypeRouterPath = 'partner';
            break;

        case ACCOUNT_TYPE_MEMBER:
            accountTypeRouterPath = 'member';
            break;

        default:
            accountTypeRouterPath = '';
            break;
    }

    accountData.forEach((account) => {
        var parentAccountId = account.accountId;

        var arenaItems = [];

        // Add link to their landing page
        var homeLink = new MenuItem('Home', 'pi-home', undefined, `/${accountTypeRouterPath}/home`, parentAccountId);
        arenaItems.push(homeLink);

        var uniqueDashboardGroupNames = [...new Set(account.accountDashboards.map((db) => db.groupName))].sort((a, b) => a > b);

        uniqueDashboardGroupNames.forEach((dgn) => {
            var acctDashboards = account.accountDashboards
                .filter((ad) => ad.groupName == dgn)
                .sort((a, b) => a.displayOrdinal > b.displayOrdinal);
            var dashboardItems = [];

            acctDashboards.forEach((acctDash) => {
                if (acctDash.dashboard.status !== DASHBOARD_STATUS_RETIRED) {
                    acctDash.dashboard.accountId = parentAccountId;
                    var iconClass = acctDash.dashboard.status == DASHBOARD_STATUS_WIP ? 'pi-cog' : 'pi-chart-line';
                    var embedData = JSON.parse(acctDash.dashboard.embedData);
                    var dashItem = new MenuItem(
                        acctDash.dashboard.name,
                        iconClass,
                        undefined,
                        `/favorites/domodashboardview/1/${embedData.details.embed_code}`
                    );
                    dashboardItems.push(dashItem);
                }
            });

            if (dashboardItems.length > 0) {
                var arenaItem = new MenuItem(dgn, 'pi-star', dashboardItems, undefined);
                arenaItems.push(arenaItem);
            }
        });

        if (account.accountTypeId == ACCOUNT_TYPE_PARTNER) {
            var partnerProfileLink = new MenuItem('Partner Profile', 'pi-user', undefined, `/partner/profile`, account.accountId);
            arenaItems.push(partnerProfileLink);

            console.log(account.accountTypeId, 'account.accountTypeId');

            var answerBankLink = new MenuItem('Answer Bank', 'pi-file', undefined, `/partner/answerbank`, account.accountId);
            arenaItems.push(answerBankLink);

            var marketingProfileLink = new MenuItem(
                'Marketing Profile',
                'pi-file',
                undefined,
                `/partner/marketing`,
                account.accountId
            );
            arenaItems.push(marketingProfileLink);
        }

        // contents will be passed as an array after the dashboard contents are received instead of undefined
        // dummy code for now
        // var dashboardsLink = new MenuItem('Dashboards', 'pi-star', undefined, `/account/${account.accountId}/dashboarddemo`);
        // arenaItems.push(dashboardsLink);

        // var libraryLink = new MenuItem('Document Library', 'pi-book', undefined, `/account/${account.accountId}/documents`);
        // arenaItems.push(libraryLink);

        // Special case -- just ARENACX users can see these links until their functionality is complete
        if (currentUser.userAccountTypeId == ACCOUNT_TYPE_ARENACX) {
            // Add Library and Opportunities links
            if (account.accountTypeId == ACCOUNT_TYPE_PARTNER) {
                var oppsLink = new MenuItem(
                    'Opportunities',
                    'pi-thumbs-up',
                    undefined,
                    `/partner/opportunities`,
                    account.accountId
                );
                arenaItems.push(oppsLink);

                var Userslink = new MenuItem('Users', 'pi-users', undefined, `/partner/users`, account.accountId);
                arenaItems.push(Userslink);
            }

            // var libraryLink = new MenuItem('Document Library', 'pi-book', undefined, `/account/documents`, account.accountId);
            // arenaItems.push(libraryLink);
        }

        // Add Invoice/Billing links
        if (account.integrationDetails != null) {
            var routeEnd = 'invoices';
            switch (account.accountTypeId) {
                case ACCOUNT_TYPE_MEMBER:
                    routeEnd = 'invoices';
                    break;
                case ACCOUNT_TYPE_PARTNER:
                    routeEnd = 'billing';
                    break;
                default:
                    routeEnd = 'invoices';
                    break;
            }

            var invoiceLink = new MenuItem('Billing', 'pi-money-bill', undefined, `/account/${routeEnd}`, account.accountId);
            arenaItems.push(invoiceLink);
        }
        //add this collection of arena links
        if (process.env.VUE_APP_ENV != 'PRODUCTION') {
            if (
                currentUser.userAccountTypeId != ACCOUNT_TYPE_ARENACX &&
                account.accountTypeId == ACCOUNT_TYPE_MEMBER &&
                account.users[0].userRole.roleKey === 'member:business:admin'
            ) {
                var partnerSelector = new MenuItem(
                    'Partner Selector',
                    'pi-question-circle',
                    undefined,
                    `/member/partnerselector`,
                    account.accountId
                );
                arenaItems.push(partnerSelector);
            }
        }

        if (
            arenaItems.length > 0 &&
            (currentUser.userAccountTypeId == ACCOUNT_TYPE_ARENACX || currentUser.userAccountTypeId == ACCOUNT_TYPE_CHANNEL)
        ) {
            var arenaItem = new MenuItem(account.name, 'pi-globe', arenaItems, undefined);
            accountItems.push(arenaItem);
        } else {
            accountItems = arenaItems;
        }
    });

    return accountItems;
}

class MenuItem {
    constructor(label, icon, children, to, id) {
        this.label = label;
        this.icon = 'pi pi-fw ' + icon;

        if (id !== undefined) {
            this.accountId = id;
        }

        // These either have child items, or are actual links to a router location
        if (children !== undefined) {
            this.items = [...children];
        }
        if (to !== undefined) {
            this.to = to;
        }
    }
}
