import CurrentUserService from '../CurrentUserService';
import SidebarMenuService from '../SidebarMenuService';
import { Auth } from 'aws-amplify';

export function getAccount() {
    const currentUser = new CurrentUserService();
    currentUser.refreshCognitoJwt();
    return currentUser.userAccountTypeId == 0 ? true : false;
}

export function getIsChannel() {
    const currentUser = new CurrentUserService();
    currentUser.refreshCognitoJwt();
    return currentUser.userAccountTypeId == 3 ? true : false;
}

export const getMenuBarData = async () => {
    const sidebarMenuService = new SidebarMenuService();
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const menuData = await sidebarMenuService.getMenuData(cognitoUser.username);
    return menuData;
};

export const setAccountId = (id) => {
    window.localStorage.setItem('accountId', id);
};

export const getAccountId = () => {
    return window.localStorage.getItem('accountId');
};

export const getTreeSelectInput = (fieldValues) => {
    let pcGrp = {};
    let parentInfos = [];
    fieldValues.forEach((val) => {
        const matchedValue = val?.match(/(.*?:\s)(.*)/);
        const parentLabel = matchedValue ? matchedValue[1]?.replace(/:\s?/, '') : val;
        const childLabel = matchedValue && matchedValue[2];

        if (!(parentLabel in pcGrp)) {
            pcGrp[parentLabel] = {
                key: parentLabel,
                label: parentLabel,
            };
            parentInfos.push(parentLabel);
        }
        if (childLabel) {
            if (!('children' in pcGrp[parentLabel])) pcGrp[parentLabel].children = [];
            pcGrp[parentLabel].children.push({ key: val, label: childLabel });
        }
    });

    return { optionValues: Object.values(pcGrp), parentInfos };
};

export const getTemplatesFromLocalStorage = (directoryName) => {
    console.log('directoryName >>>', directoryName);
    let templates = localStorage.getItem('templates');
    console.log('templates >>>', templates);
    if (templates) {
        const parsedTemplates = JSON.parse(templates);
        let item = parsedTemplates.find((template) => template.directory_name === directoryName);
        console.log('item >>>', item);
        return item.data;
    }
};
export const convertCountryDataToTreeSelect = (data) => {
    return Object.entries(data).map(([name, children]) => {
        return {
            children: children.map((x) => ({ key: x.name, label: x.name })),
            key: name,
            label: name,
        };
    });
};

export function getUserId() {
    const currentUser = new CurrentUserService();
    currentUser.refreshCognitoJwt();
    return currentUser?.marketplaceUser?.userId || '';
}
