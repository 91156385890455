<template>
    <p class="field-title">{{ label }}</p>
    <Dropdown :modelValue="selectedValue" :options="options" optionLabel="name" :placeholder="placeholder"
        @update:modelValue="handleInput" filter :disabled="isChannel" />

    <p v-if="v$.selectedValue.$error" class="error-msg">{{ error_message }}</p>
</template>
<script>
import { ref, watch } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import Dropdown from 'primevue/dropdown';
import { getIsChannel } from '../../service/common';

export default {
    name: 'SingleSelectDropdownSearchComponent',
    components: {
        Dropdown,
    },
    props: {
        label: {
            type: String,
            required: true,
        },
        modelValue: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        options: {
            type: Array,
            default: () => [],
        },
        error_message: {
            type: String,
        },
        validation_rules: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            isChannel: false,
        };
    },
    created() {
        this.isChannel = getIsChannel()
    },

    setup(props, { emit }) {
        const selectedValue = ref(props.modelValue);
        const isInOptions = (value) => {
            props.options.map((option) => option.name).includes(value.name);
        };
        const v$ = useVuelidate(
            {
                selectedValue: {
                    //isInOptions,
                },
            },
            { selectedValue }
        );
        watch(
            () => props.modelValue,
            (newVal) => {
                selectedValue.value = newVal;
                v$.value.$touch();
                if (!v$.value.selectedValue.$error) {
                    emit('isValid', true);
                } else {
                    emit('isValid', false);
                }
            }
        );

        const handleInput = (newValue) => {
            emit('update:modelValue', newValue);
            if (!v$.value.selectedValue.$error) {
                emit('isValid', true);
            } else {
                emit('isValid', false);
            }
        };

        return { selectedValue, v$, handleInput };
    },
};
</script>
